/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { forwardRef } from 'react';

import { jsx } from '@compiled/react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { cssMap } from '@atlaskit/css';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';

import { ListItem } from '../../components/list-item';

import { IconButton } from './themed/migration';

const styles = cssMap({
	hiddenForLargeViewports: {
		'@media (min-width: 48rem)': {
			display: 'none',
		},
	},
});

type ShowMoreButtonProps = {
	/**
	 * The label for the button
	 */
	children: React.ReactNode;
	onClick?: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	isSelected?: boolean;
	testId?: string;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
};

/**
 * __ShowMoreButton__
 *
 * The show more button (meatball menu) for the top navigation.
 */
export const ShowMoreButton = forwardRef<HTMLButtonElement, ShowMoreButtonProps>(
	({ children, isSelected, onClick, testId, interactionName }, forwardedRef) => (
		<ListItem xcss={styles.hiddenForLargeViewports}>
			<IconButton
				icon={ShowMoreHorizontalIcon}
				label={children}
				onClick={onClick}
				isSelected={isSelected}
				ref={forwardedRef}
				isTooltipDisabled={false}
				testId={testId}
				interactionName={interactionName}
			/>
		</ListItem>
	),
);
